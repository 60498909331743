/* eslint-disable */
<template>
  <!-- eslint-disable max-len -->
  <fragment>
    <section class="content-wrapper">
      <nav class="breadcrumb-show">
        <div class="container">
          <a href="javascript:(0)" class="breadcrumb-show__item" aria-current="page">My Design</a>
          <p class="breadcrumb-show__description">
            Print your desired look on ready-made shirts with various sizes and colors.
          </p>
        </div>
      </nav>
      <nav aria-label="breadcrumb " class="d-none d-md-block">
        <div class="container">
          <ol class="breadcrumb mb-1">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">My design</li>
          </ol>
        </div>
      </nav>
      <div class="my-design">
        <div class="container">
          <div class="best-product__content">
            <div class="best-product__content--filter">
              <ol class="product-filter justify-content-lg-end">
                <li class="product-filter__item"><a href="#" @click="handleSelectAll">Select all</a></li>
                <li class="product-fillter__item px-3">
                  <svg
                    width="1"
                    height="20"
                    viewBox="0 0 1 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="1" height="20" fill="#C6CDE7" />
                  </svg>
                </li>
                <li class="product-filter__item"><a  href="#" @click="handleDeselectAll">Select none</a></li>
                <li class="product-fillter__item px-3">
                  <svg
                    width="1"
                    height="20"
                    viewBox="0 0 1 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="1" height="20" fill="#C6CDE7" />
                  </svg>
                </li>
                <li class="product-filter__item deleted">
                  <a href="#"
                    data-bs-toggle="modal"
                    :data-bs-target="selectedMyDesignIds.length > 0 ? '#modalDeletedDesign' : ''"
                    >Delete selected</a
                  >
                </li>
              </ol>
            </div>
            <div class="row">
              <div v-for="(myDesign) in myDesigns" :key="myDesign.id" class="col-6 col-md-4 col-lg-3">
                <MyDesignCard
                  :myDesign="myDesign"
                  v-on:previewDesign="showPreviewDesign"
                  v-on:selectDesign="selectMyDesign"
                  :isSelectedAll="isSelectedAll"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <PreviewMyDesignModal
      :myDesign="selectedPreviewDesign"
    />
    <DeleteMyDesignModal
      :isShowModal="isShowDeleteConfirmModal"
      :selectedMyDesignIds="selectedMyDesignIds"
      v-on:getMyDesign="getMyDesigns"
    />
  </fragment>
</template>

<script>
import { mapActions } from 'vuex';
import PreviewMyDesignModal from '@/components/modal/PreviewMyDesignModal.vue';
import DeleteMyDesignModal from '@/components/modal/DeleteMyDesignModal.vue';
import MyDesignCard from '@/components/designs/MyDesignCard.vue';

export default {
  data() {
    return {
      selectedPreviewDesign: null,
      selectedMyDesignIds: [],
      myDesigns: [],
      isSelectedAll: false,
      isShowDeleteConfirmModal: false,
    };
  },
  async mounted() {
    this.myDesigns = await this.getDesigns();
  },
  computed: {},
  methods: {
    handleSelectMyDesign(id) {
      this.selectedMyDesignIds.push(id);
    },
    ...mapActions({
      getDesigns: 'design/getDesigns',
    }),
    showPreviewDesign(value) {
      this.selectedPreviewDesign = { ...value };
    },
    handleSelectAll() {
      this.isSelectedAll = true;
      this.isDeselectedAll = false;
      this.myDesigns.forEach((design) => {
        this.selectedMyDesignIds.push(design.id);
      });
    },
    handleDeselectAll() {
      this.isSelectedAll = false;
      this.isDeselectedAll = true;
      this.selectedMyDesignIds = [];
    },
    selectMyDesign(value) {
      if (value.selected) {
        this.selectedMyDesignIds.push(value.id);
        this.selectedMyDesignIds = [
          ...new Set(this.selectedMyDesignIds),
        ];
      } else {
        const index = this.selectedMyDesignIds.indexOf(value.id);
        if (index > -1) {
          this.selectedMyDesignIds.splice(index, 1);
        }
      }
    },
    async getMyDesigns() {
      this.myDesigns = await this.getDesigns();
    },
  },
  components: {
    MyDesignCard,
    DeleteMyDesignModal,
    PreviewMyDesignModal,
  },
};
</script>

<style lang="sass" scoped>
</style>
