<template>
      <div
      class="modal save-design fade"
      :class="{isShowModal: 'show'}"
      id="modalDeletedDesign"
      tabindex="-1"
      aria-labelledby="modalDeletedDesignLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-mb-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="save-design__title mb-3">Delete Design</div>
            <div class="save-design__confirm active">
              <div class="py-3 px-4">
                Are you sure you want to delete this design?
              </div>
            </div>
            <div class="p-4 row d-none d-md-flex">
              <div class="col-12 col-sm-6">
                <button type="button" class="btn btn-primary mb-1" data-bs-dismiss="modal">
                  Cancel
                </button>
              </div>
              <div class="col-12 col-sm-6">
                <button
                  type="button"
                  class="btn btn-primary-2 mb-1"
                  @click="handleConfirmDelete"
                >Delete</button>
              </div>
            </div>
            <div class="p-4 row d-md-none d-sm-block">
              <div class="col-12 col-sm-6">
                <button
                  type="button"
                  class="btn btn-primary-2 mb-2 w-100"
                  @click="handleConfirmDelete"
                >Delete</button>
              </div>
              <div class="col-12 col-sm-6">
                <button type="button" class="btn btn-primary w-100" data-bs-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex';

export default {
  name: 'DeleteMyDesignModal',
  props: {
    selectedMyDesignIds: {
      type: Array,
      require: true,
      default: () => {},
    },
    isShowModal: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    ...mapActions({
      deleteDesign: 'design/deleteDesign',
    }),
    async handleConfirmDelete() {
      await this.deleteDesign(this.selectedMyDesignIds);
      this.$emit('getMyDesign');
      this.hideModal();
    },
    hideModal() {
      $('#modalDeletedDesign').modal('toggle');
    }
  },
};
</script>

<style lang="sass" scoped>
</style>
