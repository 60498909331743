<template>
  <!-- eslint-disable max-len -->
  <div
    class="modal fade preview-modal"
    id="modalPreview"
    tabindex="-1"
    aria-labelledby="modalPreviewLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fix-bottom">
      <div class="modal-content">
        <div class="d-flex justify-content-between">
          <div class="p-4 pb-2 d-none d-md-block">
            <h3 class="modal-title">{{ selectedPreviewDesign.productName }}</h3>
          </div>
          <button type="button" class="btn pt-3 d-flex" data-bs-dismiss="modal" aria-label="Close">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4"
                y="3"
                width="25"
                height="1.5"
                rx="0.75"
                transform="rotate(45 4 3)"
                fill="#656782"
              />
              <rect
                x="2.93945"
                y="20.6777"
                width="25"
                height="1.5"
                rx="0.75"
                transform="rotate(-45 2.93945 20.6777)"
                fill="#656782"
              />
            </svg>
          </button>
        </div>
        <div class="modal-body p-4">
          <div class="modal-main-content">
            <div class="modal-main-content__item image-fix mt-0">
              <img
                :src="selectedPreviewDesign.thumbnail + '?x=' + Date.now()"
                class="mw-100"
                alt=""
                @load="loadImage"
                v-show="isLoadedImage"
              />
              <PuSkeleton v-show="!isLoadedImage" height="100%"></PuSkeleton>
            </div>
            <button class="btn w-100 btn-primary-2 mt-4" @click="handleEditMyDesign">Edit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- eslint-enable max-len -->
</template>

<script>
/* eslint-disable */
export default {
  name: "PreviewMyDesignModal",
  props: {
    myDesign: {
      type: Object,
      require: false,
      default: () => ({
        id: 1,
        name: "T-Shirt Uni sex for woman 1",
        date: "20 - 10 - 2022",
        img: "https://storage.googleapis.com/itify-customshirt.appspot.com/tshirt/templates/template1.png"
      })
    }
  },
  data() {
    const previewDesignDefault = {
      id: "",
      name: "T-Shirt Uni sex for woman 1",
      date: "20 - 10 - 2022",
      img: "https://storage.googleapis.com/itify-customshirt.appspot.com/tshirt/templates/template1.png"
    };
    return {
      selectedPreviewDesign: this.myDesign ? this.myDesign : previewDesignDefault,
      isLoadedImage: false,
    };
  },
  methods: {
    loadImage() {
      this.isLoadedImage = true;
    },
    handleEditMyDesign() {
      if (this.selectedPreviewDesign.id) {
        this.hideModal();
        this.$router.push(`/design/${this.selectedPreviewDesign.id}`);
      }
    },
    hideModal() {
      $("#modalPreview").modal("toggle");
    }
  },
  watch: {
    myDesign() {
      this.selectedPreviewDesign = this.myDesign;
    }
  }
};
</script>

<style lang="sass" scoped></style>
