<template>
  <!-- eslint-disable max-len -->
  <div
    class="best-product__content--item"
  >
    <div class="infomation__favorite">
      <label class="checkbox-design">
        <input type="checkbox" name="programming" v-model="selected" @change="handleSelectDesign"/>
        <span class="checkbox-square"></span>
      </label>
    </div>
    <div class="infomation__image"
      data-bs-toggle="modal"
      data-bs-target="#modalPreview"
      @click="handlePreviewDesign"
    >
      <img :src="myDesign.thumbnail + '?x=' + Date.now()" alt="" @load="loadImage" v-show="isLoadedImage"/>
      <PuSkeleton v-show="!isLoadedImage"></PuSkeleton>
    </div>
    <div
      class="infomation__content d-flex align-items-end justify-content-between"
    >
      <div>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <h3 class="mb-0 infomation__content--title text-ellipse-1">
            {{ myDesign.productName }}
          </h3>
        </div>
        <p class="mb-0 infomation__content--description">
          Date: <span class="color-black">{{ myDesign.updatedAt }}</span>
        </p>
      </div>
      <button class="btn p-0">
        <svg
          width="18"
          height="20"
          viewBox="0 0 18 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="handleEditMyDesign(myDesign.id)"
        >
          <path
            d="M5.52751 16.6902C5.54251 16.6827 5.55751 16.6752 5.58001 16.6677C5.64751 16.6377 5.71501 16.5927 5.76751 16.5402C5.77501 16.5327 5.78251 16.5327 5.79001 16.5252L15.03 7.17269L16.575 5.6127C17.7075 4.4652 17.7075 2.5977 16.575 1.4502C15.465 0.325195 13.5225 0.325195 12.42 1.4502L10.8825 3.0027L1.65001 12.3477C1.64251 12.3552 1.64251 12.3627 1.63501 12.3777C1.58251 12.4377 1.54501 12.4977 1.50751 12.5652C1.50001 12.5802 1.48501 12.5952 1.48501 12.6102C1.48501 12.6177 1.47751 12.6252 1.47001 12.6402L0.397507 15.8877C0.217507 16.4277 0.360007 17.0202 0.765007 17.4252C1.05001 17.7102 1.43251 17.8602 1.81501 17.8602C1.97251 17.8602 2.13751 17.8377 2.29501 17.7777L5.49751 16.6977C5.51251 16.6977 5.52001 16.6902 5.52751 16.6902ZM13.4925 2.4927C14.0325 1.9452 14.9775 1.9452 15.5175 2.4927C16.08 3.0552 16.08 3.97769 15.5175 4.5477L14.505 5.5752L12.48 3.5277L13.4925 2.4927ZM11.4225 4.5927L13.4475 6.6402L5.26501 14.9202L3.24001 12.8727L11.4225 4.5927ZM1.82251 16.3602L2.51251 14.2752L3.88501 15.6627L1.82251 16.3602Z"
            fill="#656782"
          />
          <path
            opacity="0.3"
            d="M16.9276 17.8975H6.0376C5.6251 17.8975 5.2876 18.235 5.2876 18.6475C5.2876 19.06 5.6251 19.3975 6.0376 19.3975H16.9276C17.3401 19.3975 17.6776 19.06 17.6776 18.6475C17.6776 18.235 17.3401 17.8975 16.9276 17.8975Z"
            fill="#656782"
          />
          <path
            opacity="0.5"
            d="M9.78003 14.9502C9.36753 14.9502 9.03003 15.2877 9.03003 15.7002C9.03003 16.1127 9.36753 16.4502 9.78003 16.4502H15.33C15.7425 16.4502 16.08 16.1127 16.08 15.7002C16.08 15.2877 15.7425 14.9502 15.33 14.9502H9.78003Z"
            fill="#656782"
          />
        </svg>
      </button>
    </div>
  </div>
  <!-- eslint-enable max-len -->
</template>

<script>
export default {
  name: 'MyDesignCard',
  data() {
    return {
      selected: false,
      isLoadedImage: false,
    };
  },
  props: {
    myDesign: {
      type: Object,
      require: true,
      default: () => ({
        id: 1,
        name: 'T-Shirt Uni sex for woman 1',
        date: '20 - 10 - 2022',
        thumbnail: 'https://storage.googleapis.com/itify-customshirt.appspot.com/tshirt/templates/template1.png',
      }),
    },
    isSelectedAll: {
      type: Boolean,
    },
  },
  methods: {
    loadImage() {
      this.isLoadedImage = true;
    },
    handleEditMyDesign(productId) {
      if (productId) { this.$router.push(`/design/${productId}`); }
    },
    handlePreviewDesign() {
      const value = {
        ...this.myDesign,
      };
      this.$emit('previewDesign', value);
    },
    handleSelectDesign() {
      const value = {
        selected: this.selected,
        id: this.myDesign.id,
      };
      this.$emit('selectDesign', value);
    },
  },
  watch: {
    isSelectedAll(value) {
      this.selected = value;
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
